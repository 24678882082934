import React from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import mouse from '../../images/slider/mouse-scroll.svg'

const Hero = () => {
  return(
  <section className="static-hero">
      <div className="hero-container">
          <div className="hero-inner">
              <div className="container-fluid">
                  <div className="slide-content">
                      <div data-swiper-parallax="300" className="slide-title">
                          <h2>Best Cleaning Service WordPress Theme</h2>
                      </div>
                      <div className="clearfix"></div>
                      <div data-swiper-parallax="500" className="slide-btns">
                        <a target="_blank" className="theme-btn" href="https://1.envato.market/cleener">Purchase Now</a>
                      </div>
                    <div className="mouse-scroll">
                        <AnchorLink href="#demo"><img src={mouse} alt="" /></AnchorLink>
                    </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
  )
}

export default Hero;